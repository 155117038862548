import { Link } from "gatsby";
import React from "react";
import { FaInstagram, FaTwitter } from "react-icons/fa";

const Footer = props => (
	<footer className="footer">
        <div className="container">
	        <div className="row">
	        	<div className="col-sm-8 footer-left">
					<span className="copyright">© {new Date().getFullYear()} cocowat</span>
				
					<ul className="list-legal">
						<li><Link to="/aviso-legal" title="Aviso legal">Aviso legal</Link></li>
						<li><Link to="/politica-privacidad" title="Política de privacidad">Política de privacidad</Link></li>
						<li><Link to="/politica-cookies" title="Política de cookies">Política de cookies</Link></li>
					</ul>
				</div>
	        	<div className="col-sm-4 footer-right">
	        		<ul className="list-social">
						<li><a href="https://twitter.com/thecocowat/" title="Twitter" rel="noopener noreferrer" target="_blank"><FaTwitter /></a></li>
						<li><a href="https://www.instagram.com/thecocowat/" title="Instagram" rel="noopener noreferrer" target="_blank"><FaInstagram /></a></li>
					</ul>
				</div>
	        </div>
        </div>
	</footer>
)

export default Footer
